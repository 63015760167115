import LayoutComponent from "components/layouts/LayoutComponent";
import React, { useState, useEffect } from "react";
import { GoChevronLeft } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonComponent, InkWellComponent } from "components/ui/UIComponent";
import FormComponent from "components/forms/FormComponent";
import { addDebt, updateDebt } from "services/debtService";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";
import { getAllAccount } from "services/accountService";

const FormDebtPage = () => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const { state } = useLocation();
  const toEdit = state ? state.debt : null;
  const [accounts, setAccounts] = useState([]);

  const [accountId, setAccountId] = useState(toEdit ? toEdit.account_id : "");
  const [dueDate, setDueDate] = useState(toEdit ? toEdit.due_date: moment().format("YYYY-MM-DD"));
  const [amount, setAmount] = useState(toEdit ? toEdit.debt_amount : "");
  const [remainingAmount, setRemainingAmount] = useState(toEdit ? toEdit.remaining_amount : "");
  const [debtType, setDebtType] = useState(toEdit ? toEdit.type : "debt");
  const [debtStatus, setDebtStatus] = useState(toEdit ? toEdit.status : "unpaid");
  const [information, setInformation] = useState(toEdit ? toEdit.information : "");

  const fetchAccounts = async () => {
    try {
      const response = await getAllAccount();
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  useEffect(() => {
    setupInterceptors(setLoading);
    fetchAccounts();
    if (toEdit) {
      setAccountId(toEdit.account_id);
      setDueDate(toEdit.due_date);
      setAmount(toEdit.debt_amount);
      setRemainingAmount(toEdit.remaining_amount);
      setDebtType(toEdit.type);
      setDebtStatus(toEdit.status);
      setInformation(toEdit.information);
    }
  }, [toEdit, setLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(accountId === "") {
      showErrorAlert("Error!", t('pages.debts.alerts.error.account'));
      return;
    }

    if (dueDate === "" || amount === "" || remainingAmount === "" || debtType === "" || debtStatus === "" || information === "") {
      showErrorAlert("Error!", t('pages.debts.alerts.error.required'));
      return;
    }

    const debt = {
      account_id: accountId,
      due_date: dueDate,
      debt_amount: amount,
      remaining_amount: remainingAmount,
      type: debtType,
      status: debtStatus,
      information: information,
    };

    if (toEdit) {
      const response = await updateDebt(toEdit.id, debt);
      if (response.status === 200) {
        showSuccessAlert("Success!", t('pages.debts.alerts.success.edit'));
        return navigate("/debts");
      }
    } else {
      const response = await addDebt(debt);
      if (response.status === 201) {
        showSuccessAlert("Success!", t('pages.debts.alerts.success.add'));
        return navigate("/debts");
      }
    }

    setAccountId("");
    setDueDate("");
    setAmount("");
    setRemainingAmount("");
    setDebtType("debt");
    setDebtStatus("cash");
    setInformation("");
  }

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => navigate("/debts")}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center h-full">
        <h1 className="text-2xl font-bold text-center">{toEdit ? `${t('pages.debts.forms.edit_debt')}` : `${t('pages.debts.forms.add_debt')}`}</h1>
      </div>

      {/* card  */}
      <div className="flex p-4 px-4 border-gray-200 rounded-xl shadow-md bg-white mt-3">
        <FormComponent
          onSubmit={handleSubmit}
          className="space-y-6 w-full px-6"
        >
          <div>
            <div className="max-w-md mx-auto">
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="due-date"
                >
                  {t('pages.debts.forms.label_details')}
                </label>
                <input
                  className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                  type="date"
                  id="due-date"
                  value={moment(dueDate).format("YYYY-MM-DD")}
                  onChange={(e) => setDueDate(moment(e.target.value).format("YYYY-MM-DD"))}
                  placeholder="Due Date"
                />
                <div className="flex space-x-2">
                  <input
                    className="w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder={t('pages.debts.forms.placeholder_amount')}
                  />
                  <input
                    className="w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    type="number"
                    value={remainingAmount}
                    onChange={(e) => setRemainingAmount(e.target.value)}
                    placeholder={t('pages.debts.forms.placeholder_remaining_amount')}
                  />
                </div>
                <div className="mt-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="account_id"
                  >
                    {t('pages.debts.forms.label_account')}
                  </label>
                  <select
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    id="account_id"
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                  >
                    <option value="" disabled>{t('pages.debts.forms.label_account')}</option>
                    {accounts.map((account) => (
                      <option key={account.id} value={account.id}>{account.type} - {account.name}</option>
                    ))}
                  </select>
                </div>

                <div className="mb-6 mt-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="type"
                  >
                    {t('pages.debts.forms.label_type')}
                  </label>
                  <div className="flex space-x-2">
                    <select
                      className="w-1/2 px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                      id="type"
                      value={debtStatus}
                      onChange={(e) => setDebtStatus(e.target.value)}
                    >
                      <option value="unpaid">{t('pages.debts.forms.option_status.unpaid')}</option>
                      <option value="overdue">{t('pages.debts.forms.option_status.overdue')}</option>
                      <option value="partially_paid">{t('pages.debts.forms.option_status.partially_paid')}</option>
                      <option value="paid">{t('pages.debts.forms.option_status.paid')}</option>
                    </select>
                    <select
                      className="w-1/2 px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                      id="type"
                      value={debtType}
                      onChange={(e) => setDebtType(e.target.value)}
                    >
                      <option value="debt">{t('pages.debts.forms.option_types.debt')}</option>
                      <option value="loan">{t('pages.debts.forms.option_types.loan')}</option>
                    </select>
                  </div>
                  <textarea 
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
                    placeholder={t('pages.debts.forms.placeholder_information')}
                    id="information"
                    value={information}
                    onChange={(e) => setInformation(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-6">
                <ButtonComponent
                  type="submit"
                  text={
                    toEdit ? `${t('pages.debts.forms.edit_debt')}` : `${t('pages.debts.forms.add_debt')}`
                  }
                  className="w-full py-3"
                />
              </div>
            </div>
          </div>
        </FormComponent>
      </div>
    </LayoutComponent>
  );
};

export default FormDebtPage;
