import { getUrl } from "utils/api";

const API_URL = `${getUrl()}`;

export const AUTH_REGISTER = `${API_URL}/auth/register`;
export const AUTH_LOGIN = `${API_URL}/auth/login`;
export const AUTH_LOGOUT = `${API_URL}/auth/logout`;

export const ITEM_URL = `${API_URL}/items`;
export const ACCOUNT_URL = `${API_URL}/accounts`;
export const DEBT_URL = `${API_URL}/debts`;
export const TRANSACTION_URL = `${API_URL}/transactions`;
export const TRX_URL = `${API_URL}/trx`;
export const REPORT_URL = `${API_URL}/reports/summary`;
export const TRANSFER_URL = `${API_URL}/transfers`;