import React from "react";
import { IoLogoGithub, IoLogoInstagram, IoLogoLinkedin } from "react-icons/io";
import moment from "moment";

const FooterComponent = () => {
  return (
    <footer className="mt-8 grid grid-cols-12 gap-4 px-8 items-center border-t border-slate-300 py-6 mx-6">
      <div className="text-xs font-semibold col-span-12 text-center sm:text-left sm:col-span-9">
        <span className="text-gray-700">
          © 2023 - {moment().format('YYYY')} by{" "}
          <a href="//instagram.com/hoyirul_" className="underline">
            Madhai
          </a>
          . All Right Reserved.
        </span>
      </div>
      <div className="grid grid-cols-3 gap-6 sm:gap-2 col-span-12 sm:mx-0 mx-auto  sm:col-span-3 text-gray-700">
        <a href="//instagram.com/hoyirul_" className="group">
          <IoLogoInstagram className="text-2xl" />
        </a>
        <a href="//linkedin.com/in/madhai" className="group">
          <IoLogoLinkedin className="text-2xl" />
        </a>
        <a href="//github.com/hoyirul" className="group">
          <IoLogoGithub className="text-2xl" />
        </a>
      </div>
    </footer>
  );
};

export default FooterComponent;
