import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/authProvider";
import FormComponent, { InputComponent, LabelComponent } from 'components/forms/FormComponent';
import { ButtonComponent } from 'components/ui/UIComponent';
import LinkComponent from "components/ui/LinkComponent";
import { register } from "services/authService";
import { showSuccessAlert } from "utils/alert";

const AuthRegisterPage = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    if (user || token) {
      navigate("/");
    }
  }, [user, navigate]); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const body = {
        name: name,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation
      };

      const response = await register(body);
      if (response.status === 201) {
        showSuccessAlert("Success!", "Account created successfully. Please login to continue.");
        navigate("/login");
      }
    } catch (error) {
      console.error('Login failed', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen px-4">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full sm:w-1/2 md:1/3 lg:w-1/3">
        <div className="flex min-h-full flex-col justify-center px-6 py-8 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create new account
            </h2>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <FormComponent onSubmit={handleSubmit}>
              <div className="space-y-2">
                <div className="space-y-2">
                  <LabelComponent htmlFor="name" text="Name" />
                  <div className="mt-2">
                    <InputComponent
                      id="name"
                      type="name"
                      name="name"
                      placeholder={"Your Full Name"}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="name"
                    />
                  </div>
                </div>
                
                <div className="space-y-2">
                  <LabelComponent htmlFor="email" text="Email" />
                  <div className="mt-2">
                    <InputComponent
                      id="email"
                      type="email"
                      name="email"
                      placeholder={"Your email"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <LabelComponent htmlFor="password" text="Password" />
                  <div className="mt-2">
                    <InputComponent
                      id="password"
                      type="password"
                      name="password"
                      placeholder={"Your password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                    />
                  </div>
                </div>

                {/* password confirmation */}
                <div className="space-y-2">
                  <LabelComponent htmlFor="password_confirmation" text="Confirm Password" />
                  <div className="mt-2">
                    <InputComponent
                      id="password_confirmation"
                      type="password"
                      name="password_confirmation"
                      placeholder={"Confirm password"}
                      value={passwordConfirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      autoComplete="current-password"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <ButtonComponent text="Sign up" type="submit" className="-mt-4" />
              </div>
            </FormComponent>

            {/* sign in */}
            <div className="mt-6">
              <p className="mt-2 text-center text-sm text-gray-600 max-w">
                Already have account?{" "}
                <LinkComponent to="/login" text="Sign in" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthRegisterPage;
