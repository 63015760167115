import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "hooks/authProvider";

const PrivateRoute = () => {
  const { access_token } = useAuth(); 

  return access_token ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
