import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from 'pages/HomePage';
import AuthLoginPage from 'pages/auth/AuthLoginPage';
import AuthRegisterPage from 'pages/auth/AuthRegisterPage';
import AccountPage from 'pages/accounts/AccountPage';
import FormAccountPage from 'pages/accounts/FormAccountPage';
import ItemPage from 'pages/items/ItemPage';
import FormItemPage from 'pages/items/FormItemPage';
import DebtPage from 'pages/debts/DebtPage';
import FormDebtPage from 'pages/debts/FormDebtPage';
import TransactionPage from 'pages/transactions/TransactionPage';
import FormTransactionPage from 'pages/transactions/FormTransactionPage';
import TransferPage from 'pages/transfers/TransferPage';
import FormTransferPage from 'pages/transfers/FormTransferPage';
import SettingPage from 'pages/settings/SettingPage';
import AuthProvider from 'hooks/authProvider';
import PrivateRoute from 'hooks/privateRoute';
import { getWithExpiry } from 'hooks/tokenExpiry';
import { useTranslation } from 'react-i18next';
import { LoadingProvider } from 'hooks/loadingProvider';
import { LoaderComponent } from 'components/ui/UIComponent';

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    const access_token = getWithExpiry("access_token");
    const language = localStorage.getItem("language") || "en";
    i18n.changeLanguage(language);
    if(!access_token) {
      localStorage.clear();
    }
  }, [i18n]);

  // localStorage.clear();
  
  return (
    <Router>
      <AuthProvider>
        <LoadingProvider>
          <LoaderComponent />
          <Routes>
            <Route path="/login" element={<AuthLoginPage />} />
            <Route path="/register" element={<AuthRegisterPage />} />
            <Route element={<PrivateRoute />}>
              <Route path="*" element={<HomePage />} />
              <Route path="/accounts" element={<AccountPage />} />
              <Route path="/accounts/add" element={<FormAccountPage />} />
              <Route path="/accounts/edit/:id" element={<FormAccountPage />} />

              <Route path="/items" element={<ItemPage />} />
              <Route path="/items/add" element={<FormItemPage />} />
              <Route path="/items/edit/:id" element={<FormItemPage />} />

              <Route path="/debts" element={<DebtPage />} />
              <Route path="/debts/add" element={<FormDebtPage />} />
              <Route path="/debts/edit/:id" element={<FormDebtPage />} />

              <Route path="/transfers" element={<TransferPage />} />
              <Route path="/transfers/add" element={<FormTransferPage />} />
              <Route path="/transfers/edit/:id" element={<FormTransferPage />} />

              <Route path="/transactions" element={<TransactionPage />} />
              <Route path="/transactions/add" element={<FormTransactionPage />} />
              <Route path="/transactions/edit/:id" element={<FormTransactionPage />} />

              <Route path="/settings" element={<SettingPage />} />
            </Route>
          </Routes>
        </LoadingProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
