export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const formatTime = (dateString) => {
  const options = { hour: 'numeric', minute: 'numeric', hour12: true };
  return new Date(dateString).toLocaleTimeString(undefined, options);
};

export const capitalize = (str) => {
  return str
    .split(' ') 
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' '); 
};

export const lowercase = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const uppercase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sentenceCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getInitialFrontCase = (str) => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('');
}

export const getInitials = (str, tcase) => {
  if (tcase === 'uppercase') {
    return str.slice(0, 2).toUpperCase();
  }

  return lowercase(str.slice(0, 2));
};

export const currencyFormat = (str) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(str);
};

export const underscoreToSpace = (str) => {
  return str.replace(/_/g, ' ');
};
