import axios from 'axios';
import { getWithExpiry } from 'hooks/tokenExpiry';
import { swalHandleError } from 'utils/alert';
import { apiSuccess } from 'utils/apiResponse';

const environment = process.env.REACT_APP_ENVIRONMENT;

let access_token = '', token_type = '';
if (getWithExpiry('access_token') !== null) {
  access_token = getWithExpiry('access_token').value;
  token_type = getWithExpiry('access_token').type;
}

const BASE_URL = environment === 'development'
  ? process.env.REACT_APP_API_DEV_URL
  : environment === 'production'
    ? process.env.REACT_APP_API_PROD_URL
    : process.env.REACT_APP_API_TEST_URL;

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setupInterceptors = (setLoading) => {
  apiClient.interceptors.request.use(
    (config) => {
      setLoading(true); // Menampilkan loader sebelum request dimulai
      const token = access_token;
      if (token) {
        config.headers.Authorization = `${token_type} ${token}`;
      }
      return config;
    },
    (error) => {
      setLoading(false); // Menyembunyikan loader jika request gagal
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      setLoading(false); // Menyembunyikan loader setelah response diterima
      return response;
    },
    (error) => {
      setLoading(false); // Menyembunyikan loader saat error terjadi
      swalHandleError(error);
      return Promise.reject(error);
    }
  );
};

export default apiClient;

export const handleApiRequest = async (requestFn, url, data = null) => {
  try {
    const response = data ? await requestFn(url, data) : await requestFn(url);
    return apiSuccess(response);
  } catch (error) {
    throw error;
  }
};

export const getUrl = () => BASE_URL;