import React from "react";
import HeaderComponent from "components/layouts/HeaderComponent";
import FooterComponent from "./FooterComponent";

export default function LayoutComponent({ children }) {
  return (
    // <div className="flex items-center justify-center min-h-screen bg-[#f1f5f9]">
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="flex flex-col bg-slate-50 max-w-xl w-full min-h-screen relative">
        <HeaderComponent />
        <main className="flex-grow px-4 sm:px-6">
          {children}
        </main>
        <FooterComponent />
      </div>
    </div>
  );
}

