import LayoutComponent from "components/layouts/LayoutComponent";
import React, { useState, useEffect } from "react";
import { GoChevronLeft } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonComponent, InkWellComponent } from "components/ui/UIComponent";
import FormComponent from "components/forms/FormComponent";
import { addItem, updateItem } from "services/itemService";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { useTranslation } from "react-i18next";
import { capitalize } from "utils/helpers";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";

const FormItemPage = () => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const { state } = useLocation(); 
  const toEdit = state ? state.item : null;

  const [itemName, setItemName] = useState(toEdit ? toEdit.name : "");

  useEffect(() => {
    setupInterceptors(setLoading);
    if (toEdit) {
      setItemName(toEdit.name);
    }
  }, [toEdit, setLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (itemName === "") {
      showErrorAlert("Error!", t('pages.items.alerts.error.required'));
      return;
    }

    const item = {
      name: capitalize(itemName),
    };

    if (toEdit) {
      const response = await updateItem(toEdit.id, item);
      if (response.status === 200) {
        showSuccessAlert("Success!", t('pages.items.alerts.success.edit'));
        return navigate("/items");
      }
    } else {
      const response = await addItem(item);
      if (response.status === 201) {
        showSuccessAlert("Success!", t('pages.items.alerts.success.add'));
        return navigate("/items");
      }
    }

    setItemName("");
  }

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => navigate("/items")}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center h-full">
        <h1 className="text-2xl font-bold text-center">{toEdit ? `${t('pages.items.forms.edit_item')}` : `${t('pages.items.forms.add_item')}`}</h1>
      </div>

      {/* card  */}
      <div className="flex p-4 px-4 border-gray-200 rounded-xl shadow-md bg-white mt-3">
        <FormComponent
          onSubmit={handleSubmit}
          className="space-y-6 w-full px-6"
        >
          <div>
            <div className="max-w-md mx-auto">
              <div className="mb-3">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="item-name"
                >
                  {t('pages.items.forms.label_details')}
                </label>
                <input
                  className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                  type="text"
                  id="item-name"
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                  placeholder={t('pages.items.forms.placeholder_item_name')}
                />
              </div>

              <div className="mb-6">
                <ButtonComponent
                  type="submit"
                  text={
                    toEdit ? `${t('pages.items.forms.edit_item')}` : `${t('pages.items.forms.add_item')}`
                  }
                  className="w-full py-3"
                />
              </div>
            </div>
          </div>
        </FormComponent>
      </div>
    </LayoutComponent>
  );
};

export default FormItemPage;
