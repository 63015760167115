import React from 'react';
import { useGlobalLoading } from 'hooks/loadingProvider';

const LoaderComponent = () => {
  const { loading } = useGlobalLoading(); 

  if (!loading) return null; 

  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export default LoaderComponent;
