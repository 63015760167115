import React from "react";
import InputComponent from "components/forms/InputComponent";
import SelectComponent from "components/forms/SelectComponent";
import LabelComponent from "components/forms/LabelComponent";

export default function FormComponent({ onSubmit, children, className }) {
  return (
    <form onSubmit={onSubmit} className={`${className || ""}`}>
      {children}
    </form>
  );
}

export {
  InputComponent,
  SelectComponent,
  LabelComponent
};