import React, { useState, useEffect } from "react";
import LayoutComponent from "components/layouts/LayoutComponent";
import { InkWellComponent, ListileComponent, PaginationComponent } from "components/ui/UIComponent";
import { GoChevronLeft, GoFilter, GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { getItems } from "services/itemService";
import { formatDate } from "utils/helpers";
import { FilterModalItem } from "components/additionals/AdditionalComponent";
import { useTranslation } from "react-i18next";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";

const ItemPage = () => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalLoading(); // Ambil setLoading dari global loader
  const [items, setItems] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const [filters, setFilters] = useState({
    name: "",
  });

  const fetchItems = async (page) => {
    try {
      const response = await getItems({ page });
      const { data, current_page, last_page } = response.data;

      setItems(data);
      setCurrentPage(current_page);
      setTotalPages(last_page);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  useEffect(() => {
    setupInterceptors(setLoading);
    fetchItems(currentPage);
  }, [currentPage, setLoading]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const handleApplyFilters = async (filter) => {
    try {
      setFilters(filter);
      closeModal();
      const response = await getItems({ page: currentPage, ...filter });
      const { data, current_page, last_page } = response.data;

      setItems(data);
      setCurrentPage(current_page);
      setTotalPages(last_page);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => navigate("/")}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <InkWellComponent onClick={() => navigate("/items/add")}>
        <div className="bg-white px-4 py-4 rounded-xl shadow-md text-pink-500">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center w-12 h-12 bg-pink-500 rounded-full">
              <GoPlus className="text-xl text-white" />
            </div>
          </div>
          <p className="text-center text-gray-600 mt-2 font-medium">{t('pages.items.add_item')}</p>
        </div>
      </InkWellComponent>

      <div className="flex justify-between mt-4 mb-3">
        <h4 className="text-left text-lg font-semibold text-gray-800">
          {t('pages.items.title')}
        </h4>
        <InkWellComponent onClick={openModal}>
          <GoFilter className="text-lg text-gray-500" />
        </InkWellComponent>
      </div>

      {
        isOpenModal && (
          <FilterModalItem
            isOpenModal={isOpenModal}
            closeModal={closeModal}
            applyFilters={handleApplyFilters}
            initialFilters={filters}
          />
        )
      }

      <div className="grid grid-cols-1 gap-2">
        { items.length === 0 ? (
          <div className="flex justify-center items-center h-48">
            <p className="text-gray-400">{t('pages.items.no_item')}</p>
          </div>
        ) : items.map((item) => {
          return (
            <InkWellComponent
                key={item.id}
                onClick={() => {
                  setItemToEdit(item);
                  navigate(`/items/edit/${item.id}`, { state: { item: item } });
                }}
              >
              <ListileComponent
                title={item.name}
                trailing={
                  <>
                    <span className="text-xs text-gray-500">{formatDate(item.created_at)}</span>
                  </>
                }
              />
            </InkWellComponent>
          );
        })}
        { totalPages > 1 &&
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        }
      </div>
    </LayoutComponent>
  );
}

export default ItemPage;
