import React, { useState, useEffect } from 'react';
import { ModalComponent } from 'components/ui/UIComponent';
import { useTranslation } from 'react-i18next';

const FilterModalAccount = ({isOpenModal, closeModal, applyFilters, initialFilters}) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    type: "",
  });

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  const handleChange = async (e) => {
    setFilters({
      ...filters,
      [e.target.id]: e.target.value
    });
  }

  const handleApply = () => {
    applyFilters(filters);
  }

  return (
    <ModalComponent
      isOpen={isOpenModal}
      onClose={closeModal}
    >
      <div className="flex flex-col space-y-2">
        <h2 className="text-xl font-semibold text-center text-gray-700">{t('pages.accounts.filters.title')}</h2>
        <div>
          <label htmlFor="type" className="block text-gray-700 text-sm font-semibold mb-1">{t('pages.accounts.filters.label_account_type')}</label>
          <select
            id="type"
            value={filters.type}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
          >
            <option value="all">{t('pages.accounts.filters.all')}</option>
            <option value="cash">Cash</option>
            <option value="bank">Bank</option>
            <option value="credit">Credit Card</option>
          </select>
        </div>
        <div className="flex justify-end">
          <button
            className="px-8 py-2 bg-pink-500 text-white rounded-md shadow-md font-semibold mt-2"
            onClick={handleApply}
          >
            {t('pages.accounts.filters.apply')}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default FilterModalAccount;