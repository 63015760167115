import React, { useState, useEffect } from 'react';

const ClockComponent = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const updateTime = () => setTime(new Date());
    const intervalId = setInterval(updateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = time.toLocaleTimeString();

  return (
    <div className="flex text-center">
      <p className="text-sm text-gray-500">{formattedTime}</p>
    </div>
  );
};

export default ClockComponent;

/*
  notes:
    - Menambahkan Transfer dengan ketentuan perpindahan dana seperti CASHOUT dan TRANSFER (tahap seeder)
    - Menambahkan Exchange dengan ketenttuan penukaran uang dari debit ke cash atau sebaliknya (tahap seeder)
    - Menambahkan Savings dengan ketentuan menabung uang (mungkin bisa ditambahkan ke transaksi dengan ITEM SAVINGS) (masih belum)
    - Masih belum ada delete
    - Masalah validasi ketika debt sudah terbayar maka tidak bisa diedit atau membayar lagi
*/
