import Swal from "sweetalert2";

export const swalHandleSuccess = (res) => {
  switch (res.status) {
    case 200:
      swal200();
      break;
    case 201:
      swal201();
      break;
    case 204:
      swal204();
      break;
    default:
      Swal.fire({
        title: "Success",
        text: "The request was successful.",
        icon: "success",
        confirmButtonText: "OK",
      });
  }
};

export const swalHandleError = (res) => {
  try{
    if(res.code === 'ERR_NETWORK') {
      Swal.fire({
        title: "Error",
        text: "A network error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    switch (res.response.status) {
      case 400:
        swal400();
        break;
      case 401:
        swal401();
        break;
      case 422:
        swal422(res.response.data);
        break;
      case 403:
        swal403();
        break;
      case 404:
        swal404();
        break;
      case 500:
        swal500();
        break;
      default:
        Swal.fire({
          title: "Error",
          text: "An error occurred. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
    }
  } catch (error) {
    Swal.fire({
      title: "Error",
      text: "An error occurred. Please try again later.",
      icon: "error",
      confirmButtonText: "OK",
    });
  }

};

const swal200 = () => {
  Swal.fire({
    title: "Success",
    text: "The request was successful.",
    icon: "success",
    confirmButtonText: "OK",
  });
}

const swal201 = () => {
  Swal.fire({
    title: "Created",
    text: "The resource was created successfully.",
    icon: "success",
    confirmButtonText: "OK",
  });
}

const swal204 = () => {
  Swal.fire({
    title: "No Content",
    text: "The request was successful but no content was returned.",
    icon: "info",
    confirmButtonText: "OK",
  });
}

const swal400 = () => {
  Swal.fire({
    title: "Bad Request",
    text: "The request was invalid. Please try again.",
    icon: "error",
    confirmButtonText: "OK",
  });
}

const swal401 = () => {
  Swal.fire({
    title: "Unauthorized",
    text: 'You are not authorized to access this resource.',
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: "OK",
  }).then(() => {
    window.location.href = "/login";
  });
};

const swal403 = () => {
  Swal.fire({
    title: "Unauthorized",
    text: "You are not authorized to access this resource.",
    icon: "error",
    confirmButtonText: "OK",
  }).then(() => {
    window.location.href = "/";
  });
}

const swal404 = () => {
  Swal.fire({
    title: "Not Found",
    text: "The resource you are looking for was not found.",
    icon: "error",
    confirmButtonText: "OK",
  });
}

const swal500 = () => {
  Swal.fire({
    title: "Server Error",
    text: "An error occurred on the server. Please try again later.",
    icon: "error",
    confirmButtonText: "OK",
  });
}

const swal422 = (errors) => {
  errors = errors.data;
  let errorMessages = "<ul>";
  
  for (const [field, messages] of Object.entries(errors)) {
    messages.forEach((msg) => {
      errorMessages += `<li>${msg}</li>`;
    });
  }
  
  errorMessages += "</ul>";

  Swal.fire({
    icon: "error",
    title: "Validation Errors",
    html: errorMessages,
    confirmButtonText: "OK",
  });
};

export const showConfirmAlert = async (title, text, confirmButtonText = 'Yes, do it!', cancelButtonText = 'No, cancel') => {
  return Swal.fire({
    title,
    text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText,
  });
};

export const showSuccessAlert = (title, text) => {
  return Swal.fire({
    title,
    text,
    icon: 'success',
  });
};

export const showErrorAlert = (title, text) => {
  return Swal.fire({
    title,
    text,
    icon: 'error',
  });
};

export const showCancelledAlert = (title, text) => {
  return Swal.fire({
    title,
    text,
    icon: 'info',
  });
};

export const showWarningAlert = (title, text) => {
  return Swal.fire({
    title,
    text,
    icon: 'warning',
  });
}

