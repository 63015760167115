export const apiSuccess = (response) => {
  try {
    if (response.data && response.data.data) {
      var data = {
        message: response.data.message,
        status: response.status,
        data: response.data.data,
      };

      return data;
    }
    return response.data; 
  } catch (error) {
    console.error('Error parsing response:', error);
    throw error;
  }
};

export const apiError = (error) => {
  if (error.response && error.response.data) {
    console.error('API Error:', error.response.data);
    throw error.response.data;
  }
  console.error('API Error:', error);
  throw error;
}