import React, { useState, useEffect } from "react";
import LayoutComponent from "components/layouts/LayoutComponent";
import { ListileComponent, InkWellComponent, DateComponent } from "components/ui/UIComponent";
import { useAuth } from "hooks/authProvider";
import { GoArchive, GoPeople, GoLog, GoStack, GoCalendar, GoChevronRight, GoGraph, GoKey, GoGitCompare } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { currencyFormat, formatDate, underscoreToSpace, formatTime } from "utils/helpers";
import { getRecentTransactions } from "services/transactionService";
import { getSummaryReport } from "services/reportService";
import { SlCloudDownload, SlCloudUpload, SlNote } from "react-icons/sl";
import { showWarningAlert } from "utils/alert";
import { useTranslation } from "react-i18next";
import { getAllItem } from "services/itemService";
import { getAllAccount } from "services/accountService";
import { useGlobalLoading } from "hooks/loadingProvider"; // Import global loader
import { setupInterceptors } from "utils/api";

const HomePage = () => {
  const { user } = useAuth();
  const { setLoading } = useGlobalLoading();
  const { t } = useTranslation();
  const userName = user?.name || "Guest";
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [summary, setSummary] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setupInterceptors(setLoading);
  }, [setLoading]);

  const fetchTransactions = async () => {
    try {
      const response = await getRecentTransactions();
      setTransactions(response.data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  }

  const fetchAccounts = async () => {
    try {
      const response = await getAllAccount();
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  }

  const fetchItems = async () => {
    try {
      const response = await getAllItem();
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  }

  const fetchSummary = async () => {
    try {
      const response = await getSummaryReport();
      setSummary(response.data);
    } catch (error) {
      console.error("Error fetching summary:", error);
    }
  }

  useEffect(() => {
    fetchTransactions();
    fetchSummary();
    fetchAccounts();
    fetchItems();
  }, []);

  const gridItems = [
    { 
      id: 1, 
      text: t("pages.home.quick_menus.accounts"),
      bgColor: "bg-cyan-100",
      textColor: "text-cyan-500",
      icon: <GoPeople className="text-2xl" />,
      navigate: () => navigate("/accounts")
    },
    {
      id: 2,
      text: t("pages.home.quick_menus.items"),
      bgColor: "bg-violet-100",
      textColor: "text-violet-500",
      icon: <GoStack className="text-2xl" />,
      navigate: () => navigate("/items")
    },
    {
      id: 3,
      text: t("pages.home.quick_menus.debts"),
      bgColor: "bg-amber-100",
      textColor: "text-amber-500",
      icon: <GoCalendar className="text-2xl" />,
      navigate: () => (accounts.length > 0 && items.length > 0) ? navigate("/debts") : showWarningAlert(t('pages.home.alerts.warning.no_data.title'), t('pages.home.alerts.warning.no_data.description'))
    },
    {
      id: 4,
      text: t("pages.home.quick_menus.transactions"),
      bgColor: "bg-rose-100",
      textColor: "text-rose-500",
      icon: <GoLog className="text-2xl" />,
      navigate: () => (accounts.length > 0 && items.length > 0) ? navigate('/transactions') : showWarningAlert(t('pages.home.alerts.warning.no_data.title'), t('pages.home.alerts.warning.no_data.description'))
    },
    {
      id: 5,
      text: t("pages.home.quick_menus.transfers"),
      bgColor: "bg-emerald-100",
      textColor: "text-emerald-500",
      icon: <GoGitCompare className="text-2xl" />,
      navigate: () => (accounts.length > 0 && items.length > 0) ? navigate("/transfers") : showWarningAlert(t('pages.home.alerts.warning.no_data.title'), t('pages.home.alerts.warning.no_data.description'))
    },
    {
      id: 6,
      text: t("pages.home.quick_menus.reports"),
      bgColor: "bg-sky-100",
      textColor: "text-sky-500",
      icon: <GoGraph className="text-2xl" />,
      navigate: () => comingSoon()
    },
    {
      id: 7,
      text: t("pages.home.quick_menus.settings"),
      bgColor: "bg-slate-100",
      textColor: "text-slate-500",
      icon: <GoKey className="text-2xl" />,
      navigate: () => navigate("/settings")
    }
  ];

  const comingSoon = () => {
    showWarningAlert("Coming Soon", "This feature is coming soon.");
    return;
  }

  return (
    <LayoutComponent>
      <div className="bg-pink-500 px-4 py-4 rounded-xl shadow-md text-white">
        <div className="font-medium flex justify-between mb-2">
          <div className="flex items-center">
            <GoArchive className="mr-2 text-xl" />
            <span className="text-sm">{t('pages.home.welcome')} {userName}</span>
          </div>
        </div>

        <div className="flex justify-center space-x-2 mb-2">
          <div className="justify-center w-full bg-white rounded-xl px-4 py-2 mt-2">
            <h3 className="text-xl font-bold text-pink-500 text-center md:text-3xl">{currencyFormat(summary.total_income)}</h3>
            <p className="text-xs text-gray-600 text-center">{t('pages.home.total_income')}</p>
          </div>
          <div className="justify-center w-full bg-white rounded-xl px-4 py-2 mt-2">
            <h3 className="text-xl font-bold text-pink-500 text-center md:text-3xl">{currencyFormat(summary.total_expense)}</h3>
            <p className="text-xs text-gray-600 text-center">{t('pages.home.total_expense')}</p>
          </div>
        </div>

        <div className="flex items-center justify-end">
          <DateComponent />
        </div>

      </div>
      
      <h4 className="text-left text-lg font-semibold text-gray-800 mt-3 mb-3">
        {t('pages.home.quick_menus.title')}
      </h4>
      
      <div className="card bg-white shadow-md p-4 rounded-xl">
        <div className="grid grid-cols-4 gap-2">
          {gridItems.map((item) => (
            <div
            key={item.id}
            className="flex flex-col items-center rounded-3xl bg-white"
            >
              <InkWellComponent 
                onClick={item.navigate} 
                className={`flex flex-col items-center space-y-2 p-2 rounded-3xl bg-white`}>
                <div className="flex justify-center">
                  <div
                    className={`flex-shrink-0 w-16 h-16 ${item.bgColor} rounded-full flex items-center justify-center`}
                  >
                    <span className={`text-lg font-bold ${item.textColor}`}>
                      {item.icon}
                    </span>
                  </div>
                </div>
                <div className="flex justify-center">
                  <p className="text-xs text-gray-600">{item.text}</p>
                </div>
              </InkWellComponent>
            </div>
          ))}
        </div>
      </div>
      
      {/* titile and see all */}
      <div className="flex justify-between items-center mt-4 mb-3">
        <h4 className="text-left text-lg font-semibold text-gray-800">
          {t('pages.transactions.recent_transactions')}
        </h4>
        <InkWellComponent onClick={
          () => navigate("/transactions")
        } className="text-sm text-gray-600">
          {t('pages.transactions.see_all')} <GoChevronRight className="inline-block text-sm" />
        </InkWellComponent>
      </div>
      <div className="grid grid-cols-1 gap-4">
      {transactions.map((trx) => {
          let bgColor = "", textColor = "", icon = null, amount = "", bgTypeColor = "", textTypeColor = "";
          if (trx.category === "INCOME") {
            icon = <SlCloudDownload className="text-2xl text-green-500" />;
            bgColor = "bg-green-100";
            textColor = "text-green-500";
            amount = `+${currencyFormat(trx.amount)}`;
          } else if (trx.category === "EXPENSE") {
            icon = <SlCloudUpload className="text-2xl text-red-500" />;
            bgColor = "bg-rose-100";
            textColor = "text-rose-500";
            amount = `-${currencyFormat(trx.amount)}`;
          } else {
            icon = <SlNote className="text-2xl text-sky-500" />;
            bgColor = "bg-sky-100";
            textColor = "text-sky-500";
            amount = currencyFormat(trx.amount);
          }
            

          if(trx.account.type === "cash") {
            bgTypeColor = "bg-indigo-100";
            textTypeColor = "text-indigo-500";
          } else if(trx.account.type === "bank") {
            bgTypeColor = "bg-sky-100";
            textTypeColor = "text-sky-500";
          } else if(trx.account.type === "credit") {
            bgTypeColor = "bg-teal-100";
            textTypeColor = "text-teal-500";
          }

          return (
            <ListileComponent
              key={trx.id}
              leading={
                <>
                  <div className={`flex-shrink-0 w-12 h-12 ${bgColor} rounded-full flex items-center justify-center mr-4`}>
                    <span className={`text-lg font-bold ${textColor}`}>{icon}</span>
                  </div>
                </>
              }
              title={trx.item.name}
              subtitle={
                <>
                  <span className="text-xs text-gray-500 mb-1">{trx.information}</span> <br />
                  <span className={`bg-gray-100 text-gray-500 p-0.5 px-2 rounded-lg text-xs mr-1`}>{trx.id}</span>
                  <span className={`${bgTypeColor} ${textTypeColor} p-0.5 px-2 rounded-lg text-xs`}>{underscoreToSpace(trx.account.type)}</span>
                </>
              }
              trailing={
                <>
                  <span className={`text-md font-semibold ${textColor}`}>{amount}</span>
                  <span className="text-xs text-gray-500">{formatDate(trx.date)}</span>
                  <span className="text-xs text-gray-500">{formatTime(trx.date)}</span>
                </>
              }
            />
          );
        })}
      </div>
    </LayoutComponent>
  );
};

export default HomePage;
