import React, { useState, useEffect } from 'react';
import { formatDate } from 'utils/helpers';

const DateComponent = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    setTime(new Date());
  }, []);

  const dateNow = time.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return (
    <div className="flex text-center">
      <h1 className="text-sm mr-1">{formatDate(dateNow)}</h1>
    </div>
  );
};

export default DateComponent;
