import LayoutComponent from "components/layouts/LayoutComponent";
import React, { useState, useEffect } from "react";
import { GoChevronLeft } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonComponent, InkWellComponent } from "components/ui/UIComponent";
import FormComponent from "components/forms/FormComponent";
import { addAccount, updateAccount } from "services/accountService";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { useTranslation } from "react-i18next";
import { capitalize } from "utils/helpers";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";

const FormAccountPage = () => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const { state } = useLocation(); // Access the passed state
  const toEdit = state ? state.account : null; // Extract account data

  const [accountName, setAccountName] = useState(toEdit ? toEdit.name : "");
  const [initialBalance, setInitialBalance] = useState(toEdit ? toEdit.initial_balance : "");
  const [currentBalance, setCurrentBalance] = useState(toEdit ? toEdit.current_balance : "");
  const [accountType, setAccountType] = useState(toEdit ? toEdit.type : "cash");
  const [bankName, setBankName] = useState(toEdit ? toEdit.bank_name : "");
  const [accountNumber, setAccountNumber] = useState(toEdit ? toEdit.account_number : "");

  useEffect(() => {
    setupInterceptors(setLoading);
    if (toEdit) {
      setAccountName(toEdit.name);
      setInitialBalance(toEdit.initial_balance);
      setCurrentBalance(toEdit.current_balance);
      setAccountType(toEdit.type);
      setBankName(toEdit.bank_name);
      setAccountNumber(toEdit.account_number);
    }
  }, [toEdit, setLoading]);

  const handleAccountType = (e) => {
    setAccountType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (accountName === "" || initialBalance === "") {
      showErrorAlert("Error!", t('pages.accounts.alerts.error.required'));
      return;
    }

    const account = {
      name: capitalize(accountName),
      initial_balance: initialBalance,
      current_balance: initialBalance,
      type: accountType,
      bank_name: bankName,
      account_number: accountNumber,
    };

    try{
      if (toEdit) {
        const response = await updateAccount(toEdit.id, account);
        if (response.status === 200) {
          showSuccessAlert("Success!", t('pages.accounts.alerts.success.edit'));
          return navigate("/accounts");
        }
      } else {
        const response = await addAccount(account);
        if (response.status === 201) {
          showSuccessAlert("Success!", t('pages.accounts.alerts.success.add'));
          return navigate("/accounts");
        }
      }
  
      setAccountName("");
      setInitialBalance("");
      setCurrentBalance("");
      setAccountType("cash");
      setBankName("");
      setAccountNumber("");
    } catch (error) {
      console.error(error);
      showErrorAlert("Error!", error.response.data.errors);
    }
  } 

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => navigate("/accounts")}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center h-full">
        <h1 className="text-2xl font-bold text-center">{toEdit ? `${t('pages.accounts.forms.edit_account')}` : `${t('pages.accounts.forms.add_account')}`}</h1>
      </div>

      {/* card  */}
      <div className="flex p-4 px-4 border-gray-200 rounded-xl shadow-md bg-white mt-3">
        <FormComponent
          onSubmit={handleSubmit}
          className="space-y-6 w-full px-6"
        >
          <div>
            <div className="max-w-md mx-auto">
              <div className="mb-3">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="account-name"
                >
                  {t('pages.accounts.forms.label_details')}
                </label>
                <input
                  className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                  type="text"
                  id="account-name"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                  placeholder={t('pages.accounts.forms.placeholder_account_name')}
                />
                <div className="flex space-x-2">
                  <input
                    className="w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    type="number"
                    value={initialBalance}
                    onChange={(e) => setInitialBalance(e.target.value)}
                    placeholder={t('pages.accounts.forms.placeholder_initial_balance')}
                  />
                  <input
                    className="w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    type="number"
                    value={(toEdit && toEdit.current_balance) || initialBalance}
                    onChange={(e) => setCurrentBalance(e.target.value)}
                    placeholder={t('pages.accounts.forms.placeholder_current_balance')}
                    disabled={true}
                  />
                </div>

                <div className="mb-3 mt-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="type"
                  >
                    {t('pages.accounts.forms.label_type')}
                  </label>
                  <select
                    className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    id="type"
                    value={accountType}
                    onChange={handleAccountType}
                  >
                    <option value="cash">Cash</option>
                    <option value="bank">Bank</option>
                    <option value="credit">Credit Card</option>
                  </select>
                  {(accountType === "bank" || accountType === "credit") && (
                    <>
                      <input
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 mb-3"
                        type="text"
                        id="bank-name"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                        placeholder="Bank Name"
                      />
                      <input
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                        type="text"
                        id="account-number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        placeholder="Account Number"
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <ButtonComponent
                  type="submit"
                  text={
                    toEdit ? `${t('pages.accounts.forms.edit_account')}` : `${t('pages.accounts.forms.add_account')}`
                  }
                  className="w-full py-3"
                />
              </div>
            </div>
          </div>
        </FormComponent>
      </div>
    </LayoutComponent>
  );
};

export default FormAccountPage;
