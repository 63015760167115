import React from "react";

const LinkComponent = ({ to, text, className }) => {
  return (
    <a href={to} className={`font-medium text-pink-600 hover:text-pink-500 ${className || ""}`}>
      {text}
    </a>
  );
}

export default LinkComponent;