import React from "react";

const InkWellComponent = ({ children, onClick, className }) => {
  return (
    <div
      onClick={onClick || null}
      className={`cursor-pointer ${className || ""}`}
    >
      {children}
    </div>
  );
}

export default InkWellComponent;