import React from "react";

const ButtonComponent = ({ type, onClick, text, className, disabled = false }) => {
  var textColor = "text-white", bgColor = "bg-pink-500", hoverColor = "hover:bg-pink-500";
  if(disabled){
    textColor = "text-gray-400";
    bgColor = "bg-gray-200";
    hoverColor = "hover:bg-gray-200";
  }

  return (
    <button
      disabled={disabled}
      type={type || "button"}
      onClick={onClick || null}
      className={`flex w-full justify-center rounded-md ${bgColor} px-3 py-1.5 text-sm font-semibold leading-6 ${textColor} shadow-sm ${hoverColor} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 ${className || ""}`}
    >
      {text}
    </button>
  );
}

export default ButtonComponent;