import apiClient from "utils/api";
import { ACCOUNT_URL } from "utils/url";
import { handleApiRequest } from "utils/api";

export const getAccounts = async (filters = {}) => {
  const params = new URLSearchParams(filters).toString();
  return handleApiRequest(apiClient.get, `${ACCOUNT_URL}?${params}`);
}

export const getAllAccount = async () => {
  return handleApiRequest(apiClient.get, `${ACCOUNT_URL}/all/show`);
}

export const getAccount = async (id) => {
  return handleApiRequest(apiClient.get, `${ACCOUNT_URL}/${id}`);
}

export const addAccount = async (account) => {
  return handleApiRequest(apiClient.post, ACCOUNT_URL, account);
}

export const updateAccount = async (id, updatedAccount) => {
  return handleApiRequest(apiClient.put, `${ACCOUNT_URL}/${id}`, updatedAccount);
}

export const deleteAccount = async (id) => {
  return handleApiRequest(apiClient.delete, `${ACCOUNT_URL}/${id}`);
}
