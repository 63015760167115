import React from "react";

const InputComponent = ({ id, type, name, value, onChange, placeholder, className }) => {
  return (
    <input
      id={id || ""}
      type={type || "text"}
      name={name || ""}
      value={value || ""}
      onChange={onChange || null}
      placeholder={placeholder || ""}
      className={`w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 ${className || ""}`}
    />
  );
};

export default InputComponent;
