import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/authProvider";
import FormComponent, { InputComponent, LabelComponent } from 'components/forms/FormComponent';
import { ButtonComponent } from 'components/ui/UIComponent';
import LinkComponent from "components/ui/LinkComponent";

const AuthLoginPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth(); 
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    if (user || token) {
      navigate("/");
    }
  }, [user, navigate]); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login({ email, password });
    } catch (error) {
      console.error('Login failed', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen px-4">
      <div className="bg-white p-4 rounded-lg shadow-lg w-full sm:w-1/2 md:1/3 lg:w-1/3">
        <div className="flex min-h-full flex-col justify-center px-6 py-8 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in Credentials
            </h2>
          </div>

          <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <FormComponent onSubmit={handleSubmit} className="space-y-6">
              <div>
                <LabelComponent htmlFor="email" text="Email" />
                <div className="mt-2">
                  <InputComponent
                    id="email"
                    type="email"
                    name="email"
                    placeholder={"Your email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between -mt-4">
                  <LabelComponent htmlFor="password" text="Password" />
                  {/* <div className="text-sm">
                    <LinkComponent to="/forgot-password" text="Forgot password?" />
                  </div> */}
                </div>
                <div className="mt-2">
                  <InputComponent
                    id="password"
                    type="password"
                    name="password"
                    placeholder={"Your password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />
                </div>
              </div>

              <div>
                <ButtonComponent 
                  text={loading ? "Signing in..." : "Sign in"}  // Display loading text
                  type="submit"
                  className="-mt-4"
                  disabled={loading}  // Disable button while loading
                />
              </div>
            </FormComponent>

            {/* sign up */}
            <div className="mt-6">
              <p className="mt-2 text-center text-sm text-gray-600 max-w">
                Don't have an account?{" "}
                <LinkComponent to="/register" text="Sign up" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLoginPage;
