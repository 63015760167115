import apiClient from 'utils/api';
import { ITEM_URL } from 'utils/url';
import { handleApiRequest } from 'utils/api';

export const getItems = async (filters = {}) => {
  const params = new URLSearchParams(filters).toString();
  return handleApiRequest(apiClient.get, `${ITEM_URL}?${params}`);
};

export const getAllItem = async () => {
  return handleApiRequest(apiClient.get, `${ITEM_URL}/all/show`);
};

export const addItem = async (item) => {
  return handleApiRequest(apiClient.post, ITEM_URL, item);
};

export const updateItem = async (id, updatedItem) => {
  return handleApiRequest(apiClient.put, `${ITEM_URL}/${id}`, updatedItem);
};

export const deleteItem = async (id) => {
  return handleApiRequest(apiClient.delete, `${ITEM_URL}/${id}`);
};
