import React, { useState, useEffect } from 'react';
import { ModalComponent } from 'components/ui/UIComponent';
import { showWarningAlert } from 'utils/alert';
import { useTranslation } from 'react-i18next';

const FilterModalDebt = ({isOpenModal, closeModal, applyFilters, initialFilters}) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    status: "",
    due_date_from: "",
    due_date_to: "",
  });

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  const handleChange = async (e) => {
    if (e.target.id === "due_date_to" && e.target.value < filters.due_date_from) {
      showWarningAlert("Warning!", "To date must be greater than From date.");
      return;
    }
    setFilters({
      ...filters,
      [e.target.id]: e.target.value
    });
  }

  const handleApply = () => {
    applyFilters(filters);
  }

  return (
    <ModalComponent
      isOpen={isOpenModal}
      onClose={closeModal}
    >
      <div className="flex flex-col space-y-2">
        <h2 className="text-xl font-semibold text-center text-gray-700">{t('pages.debts.filters.title')}</h2>
        <div>
          <label htmlFor="status" className="block text-gray-700 text-sm font-semibold mb-1">{t('pages.debts.filters.label_status')}</label>
          <select
            id="status"
            value={filters.status}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
          >
            <option value="all">{t('pages.debts.filters.option_types.all')}</option>
            <option value="unpaid">{t('pages.debts.filters.option_types.unpaid')}</option>
            <option value="overdue">{t('pages.debts.filters.option_types.overdue')}</option>
            <option value="partially_paid">{t('pages.debts.filters.option_types.partially_paid')}</option>
            <option value="paid">{t('pages.debts.filters.option_types.paid')}</option>
          </select>
        </div>
        <div>
          <label htmlFor="due_date_from" className="block text-gray-700 text-sm font-semibold mb-1">{t('pages.debts.filters.label_due_date_from')}</label>
          <input
            type="date"
            id="due_date_from"
            value={filters.due_date_from}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
          />
        </div>
        <div>
          <label htmlFor="due_date_to" className="block text-gray-700 text-sm font-semibold mb-1">{t('pages.debts.filters.label_due_date_to')}</label>
          <input
            type="date"
            id="due_date_to"
            value={filters.due_date_to}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
          />
        </div>
        <div className="flex justify-end">
          <button
            className="px-8 py-2 bg-pink-500 text-white rounded-md shadow-md font-semibold mt-2"
            onClick={handleApply}
          >
            {t('pages.debts.filters.apply')}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default FilterModalDebt;