import LayoutComponent from "components/layouts/LayoutComponent";
import React, { useState, useEffect } from "react";
import { GoChevronLeft } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonComponent, InkWellComponent } from "components/ui/UIComponent";
import FormComponent from "components/forms/FormComponent";
import { addTransfer, updateTransfer } from "services/transferService";
import { getAllAccount, getAccount } from "services/accountService";
import { showConfirmAlert, showErrorAlert, showSuccessAlert } from "utils/alert";
import moment from "moment";
import { capitalize, currencyFormat, underscoreToSpace } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";

const FormTransferPage = () => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const { state } = useLocation();
  const toEdit = state ? state.transfer : null;

  const [date, setDate] = useState(toEdit ? toEdit.date: moment().format("YYYY-MM-DD"));
  const [amount, setAmount] = useState(toEdit ? toEdit.amount : "");
  const [category, setCategory] = useState(toEdit ? toEdit.category : "");
  const [fromAccountId, setFromAccountId] = useState(toEdit ? toEdit.from_account_id : "");
  const [toAccountId, setToAccountId] = useState(toEdit ? toEdit.to_account_id : "");
  const [information, setInformation] = useState(toEdit ? toEdit.information : "");
  const [account, setAccount] = useState(null);

  const [fromAccounts, setFromAccounts] = useState([]);
  const [toAccounts, setToAccounts] = useState([]);

  const fetchAccounts = async (categorySelected = "") => {
    try {
      const response = await getAllAccount();

      if(response.data.length === 0) {
        showErrorAlert("Error!", t('pages.accounts.alerts.error.no_account'));
        return navigate("/accounts/add");
      }
      
      if(categorySelected !== "") {
        const fromAccounts = response.data.filter((fAccount) => {
          if (categorySelected === "CASHOUT") {
            return fAccount.type == "bank" || fAccount.type == "credit";
          } else if (categorySelected === "TRANSFER") {
            return fAccount.type == "bank" || fAccount.type == "credit";
          } else if (categorySelected === "DEPOSIT") {
            return fAccount.type === "cash";
          }
        });

        setFromAccounts(fromAccounts);

        const toAccounts = response.data.filter((tAccount) => {
          if (categorySelected === "CASHOUT") {
            return tAccount.type === "cash";
          } else if (categorySelected === "TRANSFER") {
            return tAccount.type === "bank" || tAccount.type === "credit";
          } else if (categorySelected === "DEPOSIT") {
            return tAccount.type === "bank" || tAccount.type === "credit";
          }
        });

        setToAccounts(toAccounts);
      }

      if (toEdit) {
        const fromAccount = response.data.find((account) => account.id === toEdit.from_account_id);
        const toAccount = response.data.find((account) => account.id === toEdit.to_account_id);

        setFromAccounts([fromAccount]);
        setToAccounts([toAccount]);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  useEffect(() => {
    setupInterceptors(setLoading);
    fetchAccounts();

    if (toEdit) {
      setDate(toEdit.date);
      setAmount(toEdit.amount);
      setCategory(toEdit.category);
      setFromAccountId(toEdit.from_account_id);
      setToAccountId(toEdit.to_account_id);
      setInformation(toEdit.information);
      handleAccountChange({ target: { value: toEdit.from_account_id } });
    }
  }, [toEdit, setLoading]);

  const handleCategoryChange = async (e) => {
    setCategory(e.target.value);
    fetchAccounts(e.target.value);
  }

  const handleAccountChange = async (e) => {
    setFromAccountId(e.target.value);
    try{
      getAccount(e.target.value).then((response) => {
        setAccount(response.data);
      });
    } catch (error) {
      console.error("Error fetching account:", error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (date === "" || amount === "" || category === "" || fromAccountId === "" || toAccountId === "") {
      showErrorAlert("Error!", t('pages.transfers.alerts.error.required'));
      return;
    } else if(account && account.current_balance < amount) {
      showErrorAlert("Error!", t('pages.transfers.alerts.error.insufficient_balance') + currencyFormat(account.current_balance));
      return;
    }

    const trx = {
      date: date,
      amount: amount,
      category: category,
      from_account_id: fromAccountId,
      to_account_id: toAccountId,
      information: information,
    };

    if (toEdit) {
      showConfirmAlert("Are you sure?", t('pages.transfers.alerts.confirm.edit')).then(async (result) => {
        if (result.isConfirmed) {
          const response = await updateTransfer(toEdit.id, trx);
          if (response.status === 200) {
            showSuccessAlert("Success!", t('pages.transfers.alerts.success.edit'));
            return navigate("/transfers");
          }
        }
      });
    } else {
      showConfirmAlert("Are you sure?", t('pages.transfers.alerts.confirm.add')).then(async (result) => {
        if (result.isConfirmed) {
          const response = await addTransfer(trx);
          if (response.status === 201) {
            showSuccessAlert("Success!", t('pages.transfers.alerts.success.add'));
            return navigate("/transfers");
          }
        }
      });
    }

    setDate(moment().format("YYYY-MM-DD"));
    setAmount("");
    setCategory("");
    setFromAccountId("");
    setToAccountId("");
    setInformation("");
  }

  var cBalanceTextColor = "", cBalanceBgColor = "";
  if (account) {
    if (account.current_balance < amount) {
      cBalanceTextColor = "text-rose-500";
      cBalanceBgColor = "bg-rose-100";
    } else {
      cBalanceTextColor = "text-emerald-500";
      cBalanceBgColor = "bg-emerald-100";
    }
  } 

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => {
            return navigate("/transfers");
          }}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center h-full">
        <h1 className="text-2xl font-bold text-center">{toEdit ? `${t('pages.transfers.forms.edit_transfer')}` : `${t('pages.transfers.forms.add_transfer')}`}</h1>
      </div>

      <div className="flex p-4 px-4 border-gray-200 rounded-xl shadow-md bg-white mt-3">
        <FormComponent
          onSubmit={handleSubmit}
          className="space-y-6 w-full px-6"
        >
          <div>
            <div className="max-w-md mx-auto">
              <div className="mb-6">
                {
                  account && (
                    <div className="flex justify-between mb-2 space-x-2">
                      <div className={`w-full ${cBalanceBgColor} px-3 py-2 rounded-lg text-center`}>
                        <p className={`text-sm ${cBalanceTextColor}`}>{t('pages.transfers.forms.current_balance')}</p>
                        <p className={`text-lg ${cBalanceTextColor} font-bold`}>{currencyFormat(account.current_balance)}</p>
                      </div>
                    </div>
                  )
                }
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="due-date"
                >
                  {t('pages.transfers.forms.label_details')}
                </label>
                <div className="flex space-x-2 justify-between">
                  <input
                    className="w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    type="date"
                    id="due-date"
                    value={moment(date).format("YYYY-MM-DD")}
                    onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                    placeholder="Due Date"
                  />
                  <select
                    className="w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    id="category"
                    value={category}
                    onChange={handleCategoryChange}
                    >
                    <option value="" disabled>{t('pages.transfers.forms.choose_category')}</option>
                    <option value="CASHOUT">{t('pages.transfers.forms.option_categories.cashout')}</option>
                    <option value="TRANSFER">{t('pages.transfers.forms.option_categories.transfer')}</option>
                    <option value="DEPOSIT">{t('pages.transfers.forms.option_categories.deposit')}</option>
                  </select>
                </div>

                <div className="mt-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="account_id"
                  >
                    {t('pages.transfers.forms.label_from_account')}
                  </label>
                  <select
                    className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    id="account_id"
                    disabled={toEdit ? true : false}
                    value={fromAccountId}
                    onChange={handleAccountChange}
                    >
                    <option value="" disabled>
                      {t('pages.transfers.forms.choose_account')}
                    </option>
                    {fromAccounts.map((account) => (
                      <option key={account.id} value={account.id}>{account.name} | {capitalize(underscoreToSpace(account.type))}</option>
                    ))}
                  </select>

                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="account_id"
                  >
                    {t('pages.transfers.forms.label_to_account')}
                  </label>
                  <select
                    className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    id="account_id"
                    disabled={toEdit ? true : false}
                    value={toAccountId}
                    onChange={(e) => setToAccountId(e.target.value)}
                    >
                    <option value="" disabled>
                      {t('pages.transfers.forms.choose_account')}
                    </option>
                    {toAccounts.map((account) => (
                      <option key={account.id} value={account.id}>{account.name} | {capitalize(underscoreToSpace(account.type))}</option>
                    ))}
                  </select>
                    
                  <input
                    className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder={t('pages.transfers.forms.placeholder_amount')}
                  />
                  <textarea 
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
                    placeholder={t('pages.transfers.forms.placeholder_information')}
                    id="information"
                    value={information}
                    onChange={(e) => setInformation(e.target.value)}
                  />
                </div>

                
              </div>

              <div className="mb-6">
                <ButtonComponent
                  type="submit"
                  disabled={(account && account.current_balance < amount) ? true : false}
                  text={
                    toEdit ? `${t('pages.transfers.forms.edit_transfer')}` : `${t('pages.transfers.forms.add_transfer')}`
                  }
                  className="w-full py-3"
                />
              </div>
            </div>
          </div>
        </FormComponent>
      </div>
    </LayoutComponent>
  );
};

export default FormTransferPage;
