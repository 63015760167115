import apiClient from "utils/api";
import { TRANSACTION_URL, TRX_URL } from "utils/url";
import { handleApiRequest } from "utils/api";

export const getTransactions = async (filters = {}) => {
  const params = new URLSearchParams(filters).toString();
  return handleApiRequest(apiClient.get, `${TRANSACTION_URL}?${params}`);
}

export const getRecentTransactions = async () => {
  return handleApiRequest(apiClient.get, `${TRX_URL}/recents`);
}

export const addTransaction = async (transaction) => {
  return handleApiRequest(apiClient.post, TRANSACTION_URL, transaction);
}

export const updateTransaction = async (id, updatedTransaction) => {
  return handleApiRequest(apiClient.put, `${TRANSACTION_URL}/${id}`, updatedTransaction);
}

export const deleteTransaction = async (id) => {
  return handleApiRequest(apiClient.delete, `${TRANSACTION_URL}/${id}`);
}
