import apiClient from "utils/api";
import { DEBT_URL } from "utils/url";
import { handleApiRequest } from "utils/api";

export const getDebts = async (filters = {}) => {
  const params = new URLSearchParams(filters).toString();
  return handleApiRequest(apiClient.get, `${DEBT_URL}?${params}`);
}

export const addDebt = async (debt) => {
  return handleApiRequest(apiClient.post, DEBT_URL, debt);
}

export const updateDebt = async (id, updatedDebt) => {
  return handleApiRequest(apiClient.put, `${DEBT_URL}/${id}`, updatedDebt);
}

export const deleteDebt = async (id) => {
  return handleApiRequest(apiClient.delete, `${DEBT_URL}/${id}`);
}
