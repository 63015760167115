import apiClient from "utils/api";
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_REGISTER } from "utils/url";
import { handleApiRequest } from "utils/api";

export const register = (credentials) => {
  return handleApiRequest(apiClient.post, AUTH_REGISTER, credentials);
}

export const login = (credentials) => {
  return handleApiRequest(apiClient.post, AUTH_LOGIN, credentials);
}

export const logout = async () => {
  const response = await handleApiRequest(apiClient.post, AUTH_LOGOUT);
  if (response.status === 200) {
    localStorage.clear();
  }
  return response;
}