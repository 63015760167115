import React, { useState, useEffect } from "react";
import { ClockComponent } from "components/ui/UIComponent";

const HeaderComponent = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`sticky top-0 left-0 right-0 z-10 p-4 px-6 transition-all duration-300 flex items-center justify-between ${
        isScrolled
          ? "backdrop-blur-md bg-white/10 shadow-md"
          : "bg-transparent"
      }`}
    >
      <h5 className="text-xl font-semibold text-pink-600">eMoge</h5>

      <ClockComponent />
    </header>
  );
};

export default HeaderComponent;
