import React, { useState, useEffect } from 'react';
import { ModalComponent } from 'components/ui/UIComponent';
import { getAllAccount } from "services/accountService";
import { capitalize, underscoreToSpace } from 'utils/helpers';
import { showWarningAlert } from 'utils/alert';
import { useTranslation } from 'react-i18next';

const FilterModalTransaction = ({isOpenModal, closeModal, applyFilters, initialFilters}) => {
  const { t } = useTranslation();  
  const [accounts, setAccounts] = useState([]);
  const [filters, setFilters] = useState({
    from: "",
    to: "",
    category: "",
    account_id: "",
  });

  const fetchAccounts = async () => {
    try {
      const response = await getAllAccount();
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  const handleChange = async (e) => {
    if (e.target.id === "to" && e.target.value < filters.from) {
      showWarningAlert("Warning!", "To date must be greater than From date.");
      return;
    }
    setFilters({
      ...filters,
      [e.target.id]: e.target.value
    });
  }

  const handleApply = () => {
    applyFilters(filters);
  }

  return (
    <ModalComponent
      isOpen={isOpenModal}
      onClose={closeModal}
    >
      <div className="flex flex-col space-y-2">
        <h2 className="text-xl font-semibold text-center text-gray-700">{t('pages.transactions.filters.title')}</h2>
        <div>
          <label htmlFor="from" className="block text-gray-700 text-sm font-semibold mb-1">{t('pages.transactions.filters.from')}</label>
          <input
            type="date"
            id="from"
            value={filters.from}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
          />
        </div>
        <div>
          <label htmlFor="to" className="block text-gray-700 text-sm font-semibold mb-1">{t('pages.transactions.filters.to')}</label>
          <input
            type="date"
            id="to"
            value={filters.to}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
          />
        </div>
        <div>
          <label htmlFor="category" className="block text-gray-700 text-sm font-semibold mb-1">{t('pages.transactions.filters.label_category')}</label>
          <select
            id="category"
            value={filters.category}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
          >
            <option value="all">{t('pages.transactions.filters.option_categories.all')}</option>
            <option value="INCOME">{t('pages.transactions.filters.option_categories.income')}</option>
            <option value="EXPENSE">{t('pages.transactions.filters.option_categories.expense')}</option>
          </select>
        </div>
        <div>
          <label htmlFor="account_id" className="block text-gray-700 text-sm font-semibold mb-1">{t('pages.transactions.filters.label_account')}</label>
          <select
            id="account_id"
            value={filters.account_id}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
          >
            <option value="all">{t('pages.transactions.filters.option_accounts.all')}</option>
            {accounts.map((account) => (
              <option key={account.id} value={account.id}>{account.name} | {capitalize(underscoreToSpace(account.type))}</option>
            ))}
          </select>
        </div>
        <div className="flex justify-end">
          <button
            className="px-8 py-2 bg-pink-500 text-white rounded-md shadow-md font-semibold mt-2"
            onClick={handleApply}
          >
            {t('pages.transactions.filters.apply')}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default FilterModalTransaction;