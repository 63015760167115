import React, { useState, useEffect } from "react";
import LayoutComponent from "components/layouts/LayoutComponent";
import { InkWellComponent, ListileComponent, PaginationComponent } from "components/ui/UIComponent";
import { GoChevronLeft, GoFilter, GoPlus } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { getDebts } from "services/debtService";
import { currencyFormat, formatDate, getInitials, sentenceCase, underscoreToSpace, uppercase } from "utils/helpers";
import { ModalComponent } from "components/ui/UIComponent";
import { FilterModalDebt } from "components/additionals/AdditionalComponent";
import { useTranslation } from "react-i18next";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";

const DebtPage = () => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalLoading();
  const [debts, setDebts] = useState([]);
  const [debtToEdit, setDebtToEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    status: "",
    due_date_from: "",
    due_date_to: "",
  });

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const openFilterModal = () => setIsOpenFilterModal(true);
  const closeFilterModal = () => setIsOpenFilterModal(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const fetchDebts = async (page) => {
    try {
      const response = await getDebts({ page });
      const {data, current_page, last_page} = response.data;

      setDebts(data);
      setCurrentPage(current_page);
      setTotalPages(last_page);
    } catch (error) {
      console.error("Error fetching debts:", error);
    }
  };

  useEffect(() => {
    setupInterceptors(setLoading);
    fetchDebts(currentPage);
  }, [currentPage, setLoading]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const handleApplyFilters = async (filter) => {
    try {
      setFilters(filter);
      closeFilterModal();
      const response = await getDebts({ page: currentPage, ...filter });
      const {data, current_page, last_page} = response.data;

      setDebts(data);
      setCurrentPage(current_page);
      setTotalPages(last_page);
    } catch (error) {
      console.error("Error fetching debts:", error);
    }
  }

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => navigate("/")}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <InkWellComponent onClick={() => navigate("/debts/add")}>
        <div className="bg-white px-4 py-4 rounded-xl shadow-md text-pink-500">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center w-12 h-12 bg-pink-500 rounded-full">
              <GoPlus className="text-xl text-white" />
            </div>
          </div>
          <p className="text-center text-gray-600 mt-2 font-medium">{t('pages.debts.add_debt')}</p>
        </div>
      </InkWellComponent>

      <div className="flex justify-between mt-4 mb-3">
        <h4 className="text-left text-lg font-semibold text-gray-800">
          {t('pages.debts.title')}
        </h4>
        <InkWellComponent onClick={openFilterModal}>
          <GoFilter className="text-lg text-gray-500" />
        </InkWellComponent>
      </div>

      {
        isOpenFilterModal && (
          <FilterModalDebt
            isOpenModal={isOpenFilterModal}
            closeModal={closeFilterModal}
            applyFilters={handleApplyFilters}
            initialFilters={filters}
          />
        )
      }

      <div className="grid grid-cols-1 gap-4">
        { debts.length === 0 ? (
          <div className="flex justify-center items-center h-48">
            <p className="text-gray-400">{t('pages.debts.no_debt')}</p>
          </div>
        ) : debts.map((debt) => {
          let bgColor = "", textColor = "";
          if (debt.status === "unpaid") {
            bgColor = "bg-rose-100";
            textColor = "text-rose-500";
          } else if (debt.status === "paid") {
            bgColor = "bg-emerald-100";
            textColor = "text-emerald-500";
          } else if (debt.status === "partially_paid") {
            bgColor = "bg-yellow-100";
            textColor = "text-yellow-500";
          } else {
            bgColor = "bg-orange-100";
            textColor = "text-orange-500";
          }
          return (
            <InkWellComponent
                key={debt.id}
                onClick={() => {
                  if (debt.status === "paid") {
                    return;
                  } else {
                    setDebtToEdit(debt);
                    openModal();
                  }
                }}
              >
              <ListileComponent
                leading={
                  <>
                    <div className={`flex-shrink-0 w-12 h-12 ${bgColor} rounded-full flex items-center justify-center mr-4`}>
                      <span className={`text-lg font-bold ${textColor}`}>{getInitials(debt.account.type)}</span>
                    </div>
                  </>
                }
                title={sentenceCase(debt.type == "debt" ? t('pages.debts.debt') : t('pages.debts.loan'))}
                subtitle={
                  <>
                    <span className="text-xs text-gray-500 mb-1">{debt.information}</span> <br />
                    <span className={`${bgColor} ${textColor} p-0.5 px-2 rounded-lg text-xs mr-1`}>{underscoreToSpace(debt.status)}</span>
                    <span className={`bg-orange-100 text-orange-500 p-0.5 px-2 rounded-lg text-xs`}>{currencyFormat(debt.debt_amount)}</span>
                  </>
                }
                trailing={
                  <>
                    <span className="text-xs text-gray-500">{debt.status == "paid" ? "Done" : "Remaining"}</span>
                    <span className={`text-md font-semibold ${textColor}`}>{currencyFormat(debt.remaining_amount)}</span>
                    <span className="text-xs text-gray-500">{formatDate(debt.due_date)}</span>
                  </>
                }
              />
            </InkWellComponent>
          );
        })}
      </div>

      { totalPages > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}

      <ModalComponent isOpen={isOpenModal} onClose={closeModal}>
        <h2 className="text-xl font-semibold mb-3 text-center text-gray-700">{t('pages.debts.modals.title')}</h2>
        <div className="flex justify-center space-x-2">
          <InkWellComponent 
            onClick={() => {
              navigate(`/debts/edit/${debtToEdit.id}`, { state: { debt: debtToEdit } });
              closeModal();
            }}
          >
            <div className={`w-full h-14 bg-pink-100 rounded-xl flex items-center justify-center px-3`}>
              <span className={`text-md font-semibold text-pink-500 md:text-lg`}>{t('pages.debts.modals.edit')}</span>
            </div>
          </InkWellComponent>
          <InkWellComponent onClick={() => {
              navigate(`/transactions/add`, { state: { debt: debtToEdit } });
              closeModal();
            }}
          >
            <div className={`w-full h-14 bg-emerald-100 rounded-xl flex items-center justify-center px-3`}>
              <span className={`text-md font-semibold text-emerald-500 md:text-lg`}>{t('pages.debts.modals.pay')}</span>
            </div>
          </InkWellComponent>
        </div>
      </ModalComponent>

    </LayoutComponent>
  );
}

export default DebtPage;
