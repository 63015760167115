import { useContext, createContext, useState, useEffect } from "react";
import { login as loginService, logout as logoutService } from "services/authService";
import Swal from "sweetalert2";
import { swalHandleError } from "utils/alert";
import { setWithExpiry } from "hooks/tokenExpiry";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [access_token, setAccessToken] = useState(() => {
    return localStorage.getItem('access_token');
  });

  const [token_type, setTokenType] = useState(() => {
    return localStorage.getItem('token_type');
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('access_token');
    const storedTokenType = localStorage.getItem('token_type');
    if (storedUser && storedToken) {
      setUser(JSON.parse(storedUser));
      setAccessToken(storedToken);
      setTokenType(storedTokenType);
    }
  }, []);

  const login = async (credentials) => {
    try {
      setLoading(true);
      const response = await loginService(credentials);
      const userData = response.data;
      localStorage.setItem('user', JSON.stringify(userData.user));

      const ttl = 2 * 60 * 60 * 1000; // save for 2 hours
      setWithExpiry("access_token", userData.access_token, userData.token_type, ttl);

      setUser(userData.user);
      setAccessToken(userData.access_token);
      setTokenType(userData.token_type);
      window.location.href = '/';
    } catch (error) {
      console.log("Error logging in:", error);
      if(error.response.status === 401) {
        Swal.fire({
          icon: 'error',
          title: 'Login failed',
          text: 'Invalid email or password',
        });
      } else {
        console.error("Error logging in:", error);
        swalHandleError(error);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const logout = async () => {
    try {
      await logoutService();
      localStorage.removeItem('access_token');
      localStorage.removeItem('token_type');
      localStorage.removeItem('user');
      setUser(null);
      setAccessToken(null);
      setTokenType(null);
      window.location.href = '/login';
    } catch (error) {
      swalHandleError(error);
      throw error;
    }
  }

  const value = {
    user,
    access_token,
    token_type,
    login,
    logout,
    loading,
  };
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
