import React from 'react';

const ListileComponent = ({ leading, title, subtitle, amount, trailing }) => {
  return (
    <div className="flex p-4 px-4 border-gray-200 rounded-xl shadow-md bg-white">
      {leading}

      <div className="flex-grow">
        <h3 className="text-md font-semibold">{title}</h3>
        <p className="text-gray-600 text-sm">{subtitle}</p>
      </div>

      <div className="flex flex-col items-end">
        {trailing}
      </div>
    </div>
  );
};

export default ListileComponent;
