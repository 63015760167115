// Fungsi untuk menyimpan data ke localStorage dengan expiration time
export const setWithExpiry = (key, value, type, ttl) => {
  const now = new Date();

  // Membuat objek untuk menyimpan value dan waktu kedaluwarsa
  const item = {
    value: value,
    type: type,
    expiry: now.getTime() + ttl, // TTL dalam milidetik
  };
  
  // Menyimpan item ke localStorage dalam bentuk string JSON
  localStorage.setItem(key, JSON.stringify(item));
};

// Fungsi untuk mengambil data dari localStorage dan memeriksa expiration time
export const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  // Jika tidak ada item di localStorage, return null
  if (!itemStr) {
    return null;
  }

  // Parse item yang disimpan di localStorage
  const item = JSON.parse(itemStr);
  const now = new Date();

  // Jika data sudah kedaluwarsa, hapus item dari localStorage dan return null
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  // Jika belum kadaluwarsa, return value-nya
  return item;
};

