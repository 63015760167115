import React from "react";

const LabelComponent = ({ htmlFor, text }) => {
  return (
    <label htmlFor={htmlFor} className="block text-sm font-medium leading-6 text-gray-800">
      {text}
    </label>
  );
}

export default LabelComponent;