import LayoutComponent from "components/layouts/LayoutComponent";
import React, { useEffect } from "react";
import { InkWellComponent, ModalComponent } from "components/ui/UIComponent";
import { useTranslation } from "react-i18next";
import { GoChevronLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/authProvider";
import { getInitialFrontCase } from "utils/helpers";
import { showConfirmAlert, showWarningAlert } from "utils/alert";
import { logout } from "services/authService";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";

const SettingPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setLoading } = useGlobalLoading();
  const { t, i18n } = useTranslation();
  const [isOpenLangModal, setIsOpenLangModal] = React.useState(false);

  const openLangModal = () => setIsOpenLangModal(true);
  const closeLangModal = () => setIsOpenLangModal(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  useEffect(() => {
    setupInterceptors(setLoading);
    const language = localStorage.getItem("language") || "en";
    i18n.changeLanguage(language);
  }, [i18n, setLoading]);

  const handleLogout = async () => { 
    const confirm = await showConfirmAlert(
      t("pages.settings.logout.subtitle"),
      t("pages.settings.logout.description"),
      t("pages.settings.logout.confirm"),
      t("pages.settings.logout.cancel")
    );

    if (confirm.isConfirmed) {
      logout().then((response) => {
        if (response.status === 200) {
          window.location.href = "/login";
        }
      });
    }
  };

  var textColorLang = "text-gray-500";
  if(i18n.language === "en") {
    textColorLang = "text-pink-500";
  } else if(i18n.language === "id") {
    textColorLang = "text-pink-500";
  }

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => navigate("/")}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center bg-gray-200 w-24 h-24 rounded-full mb-2">
          <p className="text-2xl font-bold text-gray-600">{getInitialFrontCase(user.name)}</p>
        </div>
        <p className="text-lg font-bold">{user.name}</p>
        <p className="text-sm text-gray-500">
          {user.email}
        </p>
      </div>

      <div className="flex flex-col mt-4">
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <p className="text-lg font-semibold text-gray-800">{t('pages.settings.privacy')}</p>
          <p className="text-sm text-gray-500">{t('pages.settings.privacy_subtitle')}</p>
        </div>

        <InkWellComponent 
          onClick={
            () => {
              showWarningAlert("Coming Soon", "This feature is coming soon!");
            }
          }
        >
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <p className="text-lg font-semibold text-gray-800">{t('pages.settings.security')}</p>
            <p className="text-sm text-gray-500">{t('pages.settings.security_subtitle')}</p>
          </div>
        </InkWellComponent>

        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <p className="text-lg font-semibold text-gray-800">{t('pages.settings.about')}</p>
          <p className="text-sm text-gray-500">{t('pages.settings.about_subtitle')}</p>
        </div>

        <InkWellComponent 
          onClick={openLangModal}
        >
          <div className="flex items-center justify-between p-4 border-b border-gray-200">
            <p className="text-lg font-semibold text-gray-800">{t('pages.settings.language')}</p>
            <p className="text-sm text-gray-500">{t('pages.settings.language_subtitle')}</p>
          </div>
        </InkWellComponent>

        <InkWellComponent
          onClick={handleLogout}
        >
          <div className="flex items-center justify-between p-4">
            <p className="text-lg font-semibold text-gray-800">{t('pages.settings.logout.title')}</p>
          </div>
        </InkWellComponent>
      </div>

      <ModalComponent 
        isOpen={isOpenLangModal} 
        onClose={closeLangModal}
        >
        <div className="flex flex-col">
          <InkWellComponent onClick={() => changeLanguage("en")}>
            <div className="flex items-center justify-between p-4 border-b border-gray-200">
              <p className={`text-lg font-semibold ${i18n.language === 'en' ? 'text-pink-500' : 'text-gray-500'}`}>English (US)</p>
            </div>
          </InkWellComponent>

          <InkWellComponent onClick={() => changeLanguage("id")}>
            <div className="flex items-center justify-between p-4 border-b border-gray-200">
              <p className={`text-lg font-semibold ${i18n.language === 'id' ? 'text-pink-500' : 'text-gray-500'}`}>Bahasa Indonesia</p>
            </div>
          </InkWellComponent>
        </div>
      </ModalComponent>

    </LayoutComponent>
  );
}

export default SettingPage;