import apiClient from "utils/api";
import { TRANSFER_URL } from "utils/url";
import { handleApiRequest } from "utils/api";

export const getTransfers = async (filters = {}) => {
  const params = new URLSearchParams(filters).toString();
  return handleApiRequest(apiClient.get, `${TRANSFER_URL}?${params}`);
}

export const addTransfer = async (transfer) => {
  return handleApiRequest(apiClient.post, TRANSFER_URL, transfer);
}

export const updateTransfer = async (id, updatedTransfer) => {
  return handleApiRequest(apiClient.put, `${TRANSFER_URL}/${id}`, updatedTransfer);
}

export const deleteTransfer = async (id) => {
  return handleApiRequest(apiClient.delete, `${TRANSFER_URL}/${id}`);
}
