import React, { useState, useEffect } from "react";
import LayoutComponent from "components/layouts/LayoutComponent";
import { InkWellComponent, ListileComponent, PaginationComponent } from "components/ui/UIComponent";
import { GoChevronLeft, GoFilter, GoPlus } from "react-icons/go";
import { SlCloudDownload, SlCloudUpload, SlLayers } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { getTransfers } from "services/transferService";
import {
  currencyFormat,
  formatDate,
  sentenceCase,
} from "utils/helpers";
import { useTranslation } from "react-i18next";
import FilterModalTransfer from "components/additionals/FilterModalTransfer";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";

const TransferPage = () => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalLoading();
  const [transfers, setTransfers] = useState([]);
  const [transferToEdit, setTransferToEdit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const [filters, setFilters] = useState({
    category: "",
    date_from: "",
    date_to: "",
  });

  const fetchTransfers = async (page) => {
    try {
      const response = await getTransfers({ page });
      console.log("response", response);
      const {data, current_page, last_page} = response.data;
      setTransfers(data);
      setCurrentPage(current_page);
      setTotalPages(last_page);
    } catch (error) {
      console.error("Error fetching transfers:", error);
    }
  };

  useEffect(() => {
    setupInterceptors(setLoading);
    fetchTransfers(currentPage);
  }, [currentPage, setLoading]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleApplyFilters = async (filter) => {
    try{
      setFilters(filter);
      closeModal();
      const response = await getTransfers({ page: currentPage, ...filter });
      const {data, current_page, last_page} = response.data;

      setTransfers(data);
      setCurrentPage(current_page);
      setTotalPages(last_page);
    } catch (error) {
      console.error("Error fetching transfers:", error);
    }
  }

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => navigate("/")}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <InkWellComponent onClick={() => navigate("/transfers/add")}>
        <div className="bg-white px-4 py-4 rounded-xl shadow-md text-pink-500">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center w-12 h-12 bg-pink-500 rounded-full">
              <GoPlus className="text-xl text-white" />
            </div>
          </div>
          <p className="text-center text-gray-600 mt-2 font-medium">
            {t('pages.transfers.add_transfer')}
          </p>
        </div>
      </InkWellComponent>

      <div className="flex justify-between mt-4 mb-3">
        <h4 className="text-left text-lg font-semibold text-gray-800">
          {t('pages.transfers.title')}
        </h4>
        <InkWellComponent onClick={openModal}>
          <GoFilter className="text-lg text-gray-500" />
        </InkWellComponent>
      </div>

      {
        isOpenModal && (
          <FilterModalTransfer
            isOpenModal={isOpenModal}
            closeModal={closeModal}
            applyFilters={handleApplyFilters}
            initialFilters={filters}
          />
        )
      }

      <div className="grid grid-cols-1 gap-4">
        {transfers.length === 0 ? (
          <div className="flex justify-center items-center h-48">
            <p className="text-gray-400">{t('pages.transfers.no_transfer')}</p>
          </div>
        ) : transfers.map((trf) => {
          let bgColor = "",
            textColor = "",
            icon = null,
            amount = "";
          if (trf.category === "DEPOSIT") {
            icon = <SlLayers className="text-2xl text-green-500" />;
            bgColor = "bg-green-100";
            textColor = "text-green-500";
            amount = `${currencyFormat(trf.amount)}`;
          } else if (trf.category === "CASHOUT") {
            icon = <SlCloudDownload className="text-2xl text-rose-500" />;
            bgColor = "bg-rose-100";
            textColor = "text-rose-500";
            amount = `${currencyFormat(trf.amount)}`;
          } else {
            icon = <SlCloudUpload className="text-2xl text-sky-500" />;
            bgColor = "bg-sky-100";
            textColor = "text-sky-500";
            amount = currencyFormat(trf.amount);
          }

          return (
            <InkWellComponent
              key={trf.id}
              onClick={() => {
                setTransferToEdit(trf);
                navigate(`/transfers/edit/${trf.id}`, {
                  state: { transfer: trf },
                });
              }}
            >
              <ListileComponent
                leading={
                  <>
                    <div
                      className={`flex-shrink-0 w-12 h-12 ${bgColor} rounded-full flex items-center justify-center mr-4`}
                    >
                      <span className={`text-lg font-bold ${textColor}`}>
                        {icon}
                      </span>
                    </div>
                  </>
                }
                title={sentenceCase(trf.category)}
                subtitle={
                  <>
                    <span className="text-xs text-gray-500 mb-1">
                      From {trf.from_account.name} to {" "} {trf.to_account.name}
                    </span>
                  </>
                }
                trailing={
                  <>
                    <span className={`text-md font-semibold ${textColor}`}>
                      {amount}
                    </span>
                    <span className="text-xs text-gray-500">
                      {formatDate(trf.date)}
                    </span>
                  </>
                }
              />
            </InkWellComponent>
          );
        })}
      
      { totalPages > 1 && (
        <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
      )}
      </div>
    </LayoutComponent>
  );
};

export default TransferPage;
