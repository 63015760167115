import React from "react";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const generatePagination = () => {
    const pages = [];

    if (currentPage > 3) {
      pages.push(1, 2, "...");
    }

    for (
      let i = Math.max(1, currentPage - 2);
      i <= Math.min(totalPages, currentPage + 2);
      i++
    ) {
      pages.push(i);
    }

    if (currentPage < totalPages - 2) {
      pages.push("...", totalPages - 1, totalPages);
    }

    return pages;
  };

  const handlePageChange = (page) => {
    if (page !== "..." && page !== currentPage) {
      onPageChange(page);
    }
  };

  return (
    <div className="pagination flex justify-center mt-4 space-x-1">
      {generatePagination().map((page, index) => (
        <button
          key={index}
          className={`px-3 py-1 rounded-full font-semibold transition-colors duration-200 ${
            currentPage === page
              ? "bg-pink-500 text-white shadow-lg"
              : "bg-gray-100 hover:bg-gray-200 text-gray-400"
          }`}
          onClick={() => handlePageChange(page)}
          disabled={page === "..."}
        >
          {page === "..." ? <span className="text-gray-500">...</span> : page}
        </button>
      ))}
    </div>
  );
};

export default PaginationComponent;
