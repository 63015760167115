import LayoutComponent from "components/layouts/LayoutComponent";
import React, { useState, useEffect } from "react";
import { GoChevronLeft } from "react-icons/go";
import { useNavigate, useLocation } from "react-router-dom";
import { ButtonComponent, InkWellComponent } from "components/ui/UIComponent";
import FormComponent from "components/forms/FormComponent";
import { addTransaction, updateTransaction } from "services/transactionService";
import { getAllItem } from "services/itemService";
import { getAllAccount, getAccount } from "services/accountService";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import moment from "moment";
import { capitalize, currencyFormat, formatDate, underscoreToSpace } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { useGlobalLoading } from "hooks/loadingProvider";
import { setupInterceptors } from "utils/api";

const FormTransactionPage = () => {
  const { t } = useTranslation();
  const { setLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const { state } = useLocation();
  const toEdit = state ? state.transaction : null;

  const trxDebt = state ? state.debt : null;
  const [trxDebtId, setTrxDebtId] = useState(trxDebt ? trxDebt.id : null);

  const [date, setDate] = useState(toEdit ? toEdit.date: moment().format("YYYY-MM-DD"));
  const [amount, setAmount] = useState(toEdit ? toEdit.amount : "");
  const [category, setCategory] = useState(toEdit ? toEdit.category : (!trxDebt) ? "EXPENSE" : (trxDebt.type == "debt" ? "EXPENSE" : "INCOME"));
  const [itemId, setItemId] = useState(toEdit ? toEdit.item_id : "");
  const [accountId, setAccountId] = useState(toEdit ? toEdit.account_id : "");
  const [information, setInformation] = useState(toEdit ? toEdit.information : "");
  const [account, setAccount] = useState(null);

  const [items, setItems] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const fetchItems = async () => {
    try {
      const response = await getAllItem();
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await getAllAccount();
      setAccounts(response.data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  useEffect(() => {
    setupInterceptors(setLoading);
    fetchItems();
    fetchAccounts();

    if (toEdit) {
      setDate(toEdit.date);
      setAmount(toEdit.amount);
      setCategory(toEdit.category);
      setItemId(toEdit.item_id);
      setAccountId(toEdit.account_id);
      setInformation(toEdit.information);
    }
  }, [toEdit, setLoading]);

  const handleAccountChange = async (e) => {
    setAccountId(e.target.value);
    try{
      getAccount(e.target.value).then((response) => {
        setAccount(response.data);
      });
    } catch (error) {
      console.error("Error fetching account:", error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (date === "" || amount === "" || category === "" || itemId === "" || accountId === "") {
      showErrorAlert("Error!", t('pages.transactions.alerts.error.required'));
      return;
    } else if(account && category === "EXPENSE" && account.current_balance < amount) {
      showErrorAlert("Error!", t('pages.transactions.alerts.error.insufficient_balance') + currencyFormat(account.current_balance));
      return;
    } else if (trxDebt && trxDebt.remaining_amount < amount) {
      showErrorAlert("Error!", t('pages.transactions.alerts.error.insufficient_remaining_amount') + currencyFormat(trxDebt.remaining_amount));
      return
    }

    const trx = {
      debt_id: trxDebtId,
      date: date,
      amount: amount,
      category: category,
      item_id: itemId,
      account_id: accountId,
      information: information,
    };

    if (toEdit) {
      const response = await updateTransaction(toEdit.id, trx);
      if (response.status === 200) {
        showSuccessAlert("Success!", t('pages.transactions.alerts.success.edit'));
        return navigate("/transactions");
      }
    } else {
      const response = await addTransaction(trx);
      if (response.status === 201) {
        showSuccessAlert("Success!", t('pages.transactions.alerts.success.add'));
        return navigate("/transactions");
      }
    }

    setTrxDebtId(null);
    setDate(moment().format("YYYY-MM-DD"));
    setAmount("");
    setCategory("EXPENSE");
    setItemId("");
    setAccountId("");
    setInformation("");
  }

  var cBalanceTextColor = "", cBalanceBgColor = "";
  if (account) {
    if (account.current_balance < amount && category === "EXPENSE") {
      cBalanceTextColor = "text-rose-500";
      cBalanceBgColor = "bg-rose-100";
    } else {
      cBalanceTextColor = "text-emerald-500";
      cBalanceBgColor = "bg-emerald-100";
    }
  } 

  var remainTextColor = "", remainBgColor = "";
  if (trxDebt) {
    if (trxDebt.remaining_amount < amount) {
      remainTextColor = "text-rose-500";
      remainBgColor = "bg-rose-100";
    } else {
      remainTextColor = "text-indigo-500";
      remainBgColor = "bg-indigo-100";
    }
  }

  return (
    <LayoutComponent>
      <div className="flex justify-between">
        <div className="flex items-center">
          <InkWellComponent onClick={() => {
            if (trxDebt) {
              return navigate(`/debts`);
            }
            return navigate("/transactions");
          }}>
            <GoChevronLeft className="mr-2 text-2xl font-bold mb-2" />
          </InkWellComponent>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center h-full">
        <h1 className="text-2xl font-bold text-center">{toEdit ? `${t('pages.transactions.forms.edit_transaction')}` : `${t('pages.transactions.forms.add_transaction')}`}</h1>
      </div>

      <div className="flex p-4 px-4 border-gray-200 rounded-xl shadow-md bg-white mt-3">
        <FormComponent
          onSubmit={handleSubmit}
          className="space-y-6 w-full px-6"
        >
          <div>
            <div className="max-w-md mx-auto">
              <div className="mb-6">
                {
                  account && (
                    <div className="flex justify-between mb-2 space-x-2">
                      <div className={`w-full ${cBalanceBgColor} px-3 py-2 rounded-lg text-center`}>
                        <p className={`text-sm ${cBalanceTextColor}`}>{t('pages.transactions.forms.current_balance')}</p>
                        <p className={`text-lg ${cBalanceTextColor} font-bold`}>{currencyFormat(account.current_balance)}</p>
                      </div>
                      {
                        trxDebt && (
                          <div className={`w-full ${remainBgColor} px-3 py-2 rounded-lg text-center`}>
                            <p className={`text-sm ${remainTextColor}`}>{t('pages.transactions.forms.remaining_amount')}</p>
                            <p className={`text-lg ${remainTextColor} font-bold`}>{currencyFormat(trxDebt.remaining_amount)}</p>
                          </div>
                        )
                      }
                    </div>
                  )
                }
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="due-date"
                >
                  {t('pages.transactions.forms.label_details')}
                </label>
                {
                  trxDebt && (
                    <div className="flex justify-between space-x-2">
                      <input 
                        className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 bg-gray-100" 
                        type="text" 
                        placeholder="Debt ID" 
                        id="debt_id"
                        value={trxDebtId}
                        onChange={(e) => setTrxDebtId(e.target.value)}
                        disabled
                      />
                      <div className={`w-full px-3 py-1 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500 bg-gray-100`}>
                        <p className="text-xs text-gray-500">{t('pages.transactions.forms.debt_due_date')}</p>
                        <p className={`text-xs ${(moment().format('YYYY-MM-DD') > moment(trxDebt.due_date).format('YYYY-MM-DD') ? 'text-rose-500' : 'text-emerald-500')} font-semibold`}>{formatDate(trxDebt.due_date)}</p>
                      </div>
                    </div>
                  )
                }
                <select 
                  className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                  id="item_id"
                  value={itemId}
                  onChange={(e) => setItemId(e.target.value)}
                >
                  <option value="" disabled>
                    {t('pages.transactions.forms.choose_item')}
                  </option>
                  {items.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                  ))}
                </select>
                <div className="flex space-x-2 justify-between">
                  <input
                    className="w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    type="date"
                    id="due-date"
                    value={moment(date).format("YYYY-MM-DD")}
                    onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                    placeholder="Due Date"
                  />
                  <select
                    className="w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    id="category"
                    disabled={trxDebt ? true : false}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    >
                    <option value="INCOME">{t('pages.transactions.forms.option_categories.income')}</option>
                    <option value="EXPENSE">{t('pages.transactions.forms.option_categories.expense')}</option>
                  </select>
                </div>

                <div className="mt-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="account_id"
                  >
                    {t('pages.transactions.forms.label_type')}
                  </label>
                  <select
                    className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    id="account_id"
                    value={accountId}
                    onChange={handleAccountChange}
                    >
                    <option value="" disabled>
                      {t('pages.transactions.forms.choose_account')}
                    </option>
                    {accounts.map((account) => (
                      <option key={account.id} value={account.id}>{account.name} | {capitalize(underscoreToSpace(account.type))}</option>
                    ))}
                  </select>
                    
                  <input
                    className="w-full px-3 py-2 mb-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder={t('pages.transactions.forms.placeholder_amount')}
                  />
                  <textarea 
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-pink-500" 
                    placeholder={t('pages.transactions.forms.placeholder_information')}
                    id="information"
                    value={information}
                    onChange={(e) => setInformation(e.target.value)}
                  />
                </div>

                
              </div>

              <div className="mb-6">
                <ButtonComponent
                  type="submit"
                  text={
                    toEdit ? `${t('pages.transactions.forms.edit_transaction')}` : `${t('pages.transactions.forms.add_transaction')}`
                  }
                  className="w-full py-3"
                />
              </div>
            </div>
          </div>
        </FormComponent>
      </div>
    </LayoutComponent>
  );
};

export default FormTransactionPage;
